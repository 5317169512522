<template>
    <div class="page-content">
        <b-row>
            <b-col md="10">
                <page-breadcrumb title="Cashflow Management" class="mb-3" />
            </b-col>
        </b-row>
        <b-media>
            <section class="py-1">
                <h2 class="mb-2">Cashflow Management</h2>
                <cashflow-filter @getList="getList" @exportData="exportData" />
                <b-card>
                <cashflow-table
                    @getList="getList"
                    :items="userBalances"
                    :is-loading="isLoading"
                />
                </b-card>
            </section>
        </b-media>
        <b-pagination
            class="mt-auto"
            pills
            size="lg"
            align="center"
            v-model="current_page"
            :total-rows="total_items"
            :per-page="items_perpage"
        />
    </div>
</template>
<script>
import service from "../service"
import CashflowTable from "./_components/CashflowTable.vue"
import CashflowFilter from "./_components/CashflowFilter.vue"
import XLSX from "xlsx"
export default {
    components:{
        CashflowTable,
        CashflowFilter
    },
    data(){
        return {
            userBalances: [],
            total_items: 0,
            current_page: 1,
            items_perpage: 50,
            isLoading: true,
            export_data: [],
            query: {
                time_range: new Date(new Date().getFullYear(), new Date().getMonth(), 1) + " to " + new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            }
        }
    },
     watch: {
        current_page() {
            this.getList();
        }
    },
    created(){
        this.getList();
    },
    methods: {
        async getList(query = null) {
            if (query){
                this.query = query
            }
            let response = await service.getList({
                page: this.current_page,
                limit: this.items_perpage,
                query: JSON.stringify(this.query)
            });
            if (response.data.type === "DATA") {
                this.userBalances = response.data.data.list;
                this.total_items = response.data.data.total;
            }
            this.isLoading = false
        },
        async exportData(){
            let response = await service.getList({query: JSON.stringify({...this.query, is_export: true})});
            if (response.data.type === "DATA") {
                this.export_data = response.data.data.list;
            }
            this.export_data = this.export_data.map((result)=>{
                return {
                    user_id: result.user_id,
                    currency: service.convertCurrency(result.currency),
                    side: service.convertSide(result.side),
                    amount: result.amount,
                    balance: result.balance,
                    type: service.convertSourceType(result.source_type),
                    date: new Date(result.occur_time).toISOString().split('T')[0] + " " + new Date(result.occur_time).toLocaleTimeString('vi-VN')
                }
            })
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(this.export_data, { origin: 'A2', skipHeader: true });
            const header = [['USER ID', 'NAME NFT', 'IMAGE URI', 'TOKEN ID', 'TYPE', 'QUALITY']];
            XLSX.utils.sheet_add_aoa(ws, header);
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, `nft_management.xlsx`);
            this.export_results = [];
        },
    }
}
</script>