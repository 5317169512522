<template>
    <div>
        <b-table :items="items" :fields="fields">
            <template #cell(name)="data">
                <span class="click-able" @click="current_item = data.item"> {{ data.item.nft_item.name }} </span>
            </template>
            <template #cell(img_uri)="data">
                <img :src="data.item.nft_item.img_uri" alt="season img" width="160">
            </template>
        </b-table>
    </div>
</template>
<script>
export default {
    props:{
        items: {type: Array, default: null},
        is_loading: {type: Boolean, default: true}
    },
    data(){
        return {
            fields: [
                {
                    key: "user_id",
                    label: "User id",
                    class: 'text-center'
                },
                {
                    key: "currency",
                    label: "Currency",
                    class: 'text-center',
                    formatter: value => {
                        return this.convertCurrency(value)
                    },
                },
                {
                    key: "side",
                    label: "Side",
                    class: 'text-center',
                    formatter: value => {
                        return this.convertSide(value)
                    },
                },
                {
                    key: "amount",
                    label: "Amount",
                    class: 'text-center'
                },
                {
                    key: "balance",
                    label: "Balance",
                    class: 'text-center'
                },
                {
                    key: "source_type",
                    label: "Type",
                    class: 'text-center',
                    formatter: value => {
                        return this.convertSourceType(value)
                    },
                },
                {
                    key: "occur_time",
                    label: "Occur date",
                    class: 'text-center',
                    formatter: value => {
                        return new Date(value).toISOString().split('T')[0] + " " + new Date(value).toLocaleTimeString('vi-VN')
                    },
                },
            ],
            current_item: null,
        }
    },
    methods: {
        convertSourceType(value){
            switch (value){
                case 1: return "BATERY RECHARGE"
                case 2: return "TUTOR LEVELUP"
                case 3: return "TUTOR LEVELUP BOOST"
                case 4: return "TUTOR UPDATE SKILL"
                case 5: return "ACTIVITY EARN"
                case 6: return "NFT UNBOX"
                case 7: return "TRANSFER"
                case 8: return "TRANSFER NFT"
                case 9: return "MINT NFT"
                case 10: return "GEM PURCHASE"
                case 11: return "ENERGY PURCHASE BY GEM"
                case 12: return "USER REFERRAL"
                case 13: return "TICKET PURCHASE"
                case 14: return "LOOTBOX OPEN"
                case 15: return "TUTOR MINT"
                case 16: return "CLAIM MAIL REWARD"
                case 17: return "CLAIM MISSION REWARD"
                case 18: return "GIFT ORDER"
                default: return "Unknown"
            }
        },
        convertCurrency(value){
            switch (value){
                case 1: return "GOGA Token"
                case 2: return "Gem"
                default: return "Unknown"
            }
        },
        convertSide(value){
            switch (value){
                case 1: return "In"
                case 0: return "Out"
                default: return "Unknown"
            }
        },
    }
}
</script>
<style scoped>
    .click-able:hover {
        cursor: pointer;
        color: #1198F6
    }
</style>