import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/earns/cashflows/list-management', { params })
    return response
  },
  convertSourceType(value){
    switch (value){
        case 1: return "BATERY RECHARGE"
        case 2: return "TUTOR LEVELUP"
        case 3: return "TUTOR LEVELUP BOOST"
        case 4: return "TUTOR UPDATE SKILL"
        case 5: return "ACTIVITY EARN"
        case 6: return "NFT UNBOX"
        case 7: return "TRANSFER"
        case 8: return "TRANSFER NFT"
        case 9: return "MINT NFT"
        case 10: return "GEM PURCHASE"
        case 11: return "ENERGY PURCHASE BY GEM"
        case 12: return "USER REFERRAL"
        case 13: return "TICKET PURCHASE"
        case 14: return "LOOTBOX OPEN"
        case 15: return "TUTOR MINT"
        case 16: return "CLAIM MAIL REWARD"
        case 17: return "CLAIM MISSION REWARD"
        case 18: return "GIFT ORDER"
        default: return "Unknown"
    }
  },
  convertCurrency(value){
      switch (value){
          case 1: return "GOGA Token"
          case 2: return "Gem"
          default: return "Unknown"
      }
  },
  convertSide(value){
      switch (value){
          case 1: return "In"
          case 0: return "Out"
          default: return "Unknown"
      }
  },
//   async create(payload) {
//     const response = await api.post('/admin/earns/balance', payload)
//     return response.data.data.new_document || null
//   },
//   async update(payload) {
//     let response = await api.put('/admin/earns/balance', payload)
//     return response
//   },
//   async delete(payload) {
//     const response = await api.delete('/admin/earns/balance', {
//       data: payload,
//     })
//     return response
//   },
}
