<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group label="User ID" label-for="user_id">
          <b-form-input
            v-model="dataForm.user_id"
            id="user_id"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Currency" label-for="currency">
          <b-form-select
            v-model="dataForm.currency"
            id="currency"
            :options="currency_options"
          />
        </b-form-group>
      </b-col>
      
    </b-row>
    <b-row>
      <b-col md="2">
        <b-form-group label="Type" label-for="type">
          <b-form-select
            v-model="dataForm.source_type"
            id="type"
            :options="type_options"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Side" label-for="side">
          <b-form-select
            v-model="dataForm.side"
            id="side"
            :options="side_options"
          />
        </b-form-group>
      </b-col>
      <b-col class="d-flex align-items-center">
        <datetime-picker :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z', mode: 'range'}"
          v-model="dataForm.time_range"
          class="ml-2"
        />
        <b-button @click="search" class="ml-3" variant="primary">
          Filter
        </b-button>
        <b-button @click="exportData" class="ml-1" variant="success">
          Export
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {
        currency: null,
        source_type: null, 
        side: null,
        time_range: [
            new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ]
      },
      type_options: [
        {text: "All", value: null},
        {text: "BATERY RECHARGE", value: 1},
        {text: "TUTOR LEVELUP", value: 2},
        {text: "TUTOR LEVELUP BOOST", value: 3},
        {text: "TUTOR UPDATE SKILL", value: 4},
        {text: "ACTIVITY EARN", value: 5},
        {text: "NFT UNBOX", value: 6},
        {text: "TRANSFER", value: 7},
        {text: "TRANSFER NFT", value: 8},
        {text: "MINT NFT", value: 9},
        {text: "GEM PURCHASE", value: 10},
        {text: "ENERGY PURCHASE BY GEM", value: 11},
        {text: "USER REFERRAL", value: 12},
        {text: "TICKET PURCHASE", value: 13},
        {text: "LOOTBOX OPEN", value: 14},
        {text: "TUTOR MINT", value: 15},
        {text: "CLAIM MAIL REWARD", value: 16},
        {text: "CLAIM MISSION REWARD", value: 17},
        {text: "GIFT ORDER", value: 18},
      ],
      currency_options:[
        {text: "All", value: null},
        {text: "Token", value: 1},
        {text: "Gem", value: 2}
      ],
      side_options:[
        {text: "All", value: null},
        {text: "In", value: 1},
        {text: "Out", value: 0}
      ]
    };
  },
  methods: {
    search() {
      this.$emit("getList", this.dataForm);
    },
    exportData(){
      this.$emit("exportData")
    }
  },
};
</script>
